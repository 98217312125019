import repository from './repository'

const resource = 'budget-codes'

export default {
  index (params) {
    return repository.get(resource, { params })
  },
  store (data) {
    return repository.post(resource, data)
  },
  update (id, data) {
    return repository.put(`${resource}/${id}`, data)
  },
}
