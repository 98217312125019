<template lang="pug">
  v-form
    v-row
      v-col(cols="12")
        v-row
          v-col(offset-lg="6" cols="3")
            v-btn(
              color="yellow darken-3"
              small
              outlined
              block
              @click="update"
              :loading="voucherUpdatePutting"
            )
              span Update
          v-col(cols="3")
            v-btn(
              color="red"
              small
              outlined
              block
              @click="$router.push('/vouchers')"
            )
              span Cancel
          v-col(lg="3" cols="12").ma-0.py-1
            v-combobox(
              label="Pay to"
              :search-input.sync="personIndexSearch"
              item-value="id"
              item-text="id"
              v-model="payTo"
              :items="personIndexList"
              :loading="personIndexGetting"
              :error-messages="voucherUpdatePutErrors.pay_to"
              no-filter
              return-object
              dense
            )
              template(v-slot:selection="{ item }")
                div(v-if="item.info")
                  span.primary--text {{ item.id }}
                  small.ml-2.blue-grey--text {{ item.info.name }}
                  small.ml-2.blue-grey--text ({{ item.type }})
                div(v-else-if="$objectEmpty(item)") &nbsp;
                div(v-else)
                  span.primary--text {{ item.id }}
                  small.ml-2.blue-grey--text {{ item.name }}
                  small.ml-2.blue-grey--text ({{ item.type }})
              template(v-slot:item="{ item }")
                span.primary--text {{ item.id }}
                small.ml-2.blue-grey--text {{ item.info.name }}
                small.ml-2.blue-grey--text ({{ item.type }})
          v-col(lg="3" cols="12").ma-0.py-1
            v-text-field(
              label="Address"
              v-model="address"
              :error-messages="voucherUpdatePutErrors.address"
              dense
            )
          v-col(lg="3" cols="12").ma-0.py-1
            v-text-field(
              label="Date"
              type="date"
              v-model="date"
              :error-messages="voucherUpdatePutErrors.date"
              dense
            )
          v-col(lg="3" cols="12").ma-0.py-1
            v-autocomplete(
              label="Project Code"
              v-model="projectCodeId"
              item-value="id"
              item-text="description"
              :items="projectCodeIndexList"
              :loading="projectCodeIndexGetting"
              :search-input.sync="searchProjectCode"
              :error-messages="voucherUpdatePutErrors.project_code_id"
              clearable
              no-filter
              dense
            )
              tempalte(v-slot:selection="{ item }")
                span.primary--text {{ item.project_code }}
              template(v-slot:item="{ item }")
                span.primary--text {{ item.project_code }}
                small.ml-2.blue-grey--text {{ item.description }}
          v-col(lg="3" cols="12").ma-0.py-1
            v-autocomplete(
              label="Budget Code"
              v-model="budgetCodeId"
              item-value="id"
              item-text="budget_code"
              :items="budgetCodeIndexList"
              :search-input.sync="searchBudgetCode"
              :loading="budgetCodeIndexGetting"
              dense
              :error-messages="voucherUpdatePutErrors.budget_code_id"
              no-filter
            )
              template(v-slot:selection="{ item }")
                span {{ item.budget_code }}
              template(v-slot:item="{ item }")
                span.primary--text {{ item.budget_code }}
                small.blue-grey--text.ml-2 {{ item.description }}
          v-col(lg="3" cols="12").ma-0.py-1
            v-autocomplete(
              label="Paid By"
              item-value="id"
              item-text="description"
              :items="voucherPaidByList"
              :loading="voucherPaidByGetting"
              v-model="paidBy"
              dense
              :error-messages="voucherUpdatePutErrors.paid_by"
            )
              template(v-slot:item="{ item }")
                span.primary--text {{ item.code }}
                span.ml-2.blue-grey--text {{ item.description }}
          //- v-col(lg="3" cols="12")
          //-   v-text-field(
          //-     label="Approval"
          //-     v-model="approval"
          //-   )
          v-col(lg="3" cols="12").ma-0.py-1
            v-text-field(
              label="Voucher Status"
              readonly
              dense
              :value="defaultData.status ? defaultData.status.description : null"
              :error-messages="voucherUpdatePutErrors.voucher_status_id"
            )
          v-col(lg="3" cols="12").ma-0.py-1
            v-autocomplete(
              label="Cost Center"
              v-model="costCenterId"
              item-value="id"
              dense
              item-text="description"
              :items="costCenterList"
              :error-messages="voucherUpdatePutErrors.cost_center_id"
            )
              template(v-slot:item="{ item }")
                span {{ item.code }}&nbsp;
                small.blue-grey--text.ml-2 {{ item.description }}
</template>
<script>
import budgetCodeRepository from '@/repositories/budget-code.repository'
import projectCodeRepository from '@/repositories/project-code.repository'
import personRepository from '@/repositories/person.repository'
import voucherRepository from '@/repositories/voucher.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import voucherPaidByRepository from '@/repositories/voucher-paid-by.repository'
import costCenterRepository from '@/repositories/cost-center.repository'
import { requestVars } from '@/libs/api-helper.extra'

const [voucherPaidByGetVars, voucherPaidByGetVarNames] = requestVars({ identifier: 'voucher-paid-by' })
const [projectCodeIndexVars, projectCodeIndexVarNames] = requestVars({ identifier: 'project-code-index', pagination: true })
const [budgetCodeIndexVars, budgetCodeIndexVarNames] = requestVars({ identifier: 'budget-code-index' })
const [costCenterGetVars, costCenterGetVarNames] = requestVars({ identifier: 'cost-center' })
const [personIndexVars, personIndexVarNames] = requestVars({ identifier: 'person-index' })
const [voucherDataVars, voucherDataVarNames] = requestVars({ identifier: 'voucher-data', data: 'defaultData' })
const [voucherUpdateVars, voucherUpdateVarNames] = requestVars({ identifier: 'voucher-update', hasData: false, request: 'put' })

const voucherPaidByHandler = new VueRequestHandler(null, voucherPaidByGetVarNames)
const projectCodeIndexHandler = new VueRequestHandler(null, projectCodeIndexVarNames, {}, true)
const budgetCodeIndexHandler = new VueRequestHandler(null, budgetCodeIndexVarNames)
const costCenterGetHandler = new VueRequestHandler(null, costCenterGetVarNames)
const personIndexHandler = new VueRequestHandler(null, personIndexVarNames)
const voucherDataHandler = new VueRequestHandler(null, voucherDataVarNames)
const voucherUpdateHandler = new VueRequestHandler(null, voucherUpdateVarNames)

const inputVars = () => ({
  id: null,
  address: null,
  payTo: {},
  date: Window.getCurrentDate(),
  projectCodeId: null,
  budgetCodeId: null,
  paidBy: null,
  approval: null,
  voucherStatusId: 'NW',
  voucherDetails: [],
  costCenterId: null,
})

export default {
  name: 'EditVoucher',
  data() {
    return {
      ...inputVars(),
      ...voucherPaidByGetVars,
      ...projectCodeIndexVars,
      ...budgetCodeIndexVars,
      ...costCenterGetVars,
      ...personIndexVars,
      ...voucherDataVars,
      ...voucherUpdateVars,
      editPersonIndex: false,
      personIndexSearch: null,
      searchProjectCode: null,
      searchBudgetCode: null,
    }
  },
  computed: {
    voucherId() {
      return this.$route.params.voucherId
    },
    payToType() {
      const { payTo } = this
      if (payTo.info) return payTo.info.type
      return payTo.type
    },
  },
  created() {
    this.getCostCenters()
    this.getBudgetCodes()
    this.getProjectCodes()
    this.voucherPaidBy()
    this.getPersonIndex()
    this.getVoucherData()
  },
  watch: {
    payTo(data) {
      if (data.info.id === this.defaultData.pay_to.id) {
        this.address = this.defaultData.pay_to.address
        return
      }
      this.address = data.info.address || null
    },
    personIndexSearch() {
      this.getPersonIndex()
    },
    defaultData() {
      const input = inputVars()
      this.$populateInputData({ input })
    },
    searchProjectCode() {
      this.getProjectCodes()
    },
    searchBudgetCode() {
      this.getBudgetCodes()
    },
  },
  methods: {
    getCostCenters() {
      const handler = costCenterGetHandler
      const repository = costCenterRepository.index
      handler.setVue(this)
      handler.execute(repository)
    },
    getBudgetCodes() {
      const handler = budgetCodeIndexHandler
      const repository = budgetCodeRepository.index
      const params = {
        limit: this.itemsPerPage,
        search: this.searchBudgetCode,
      }
      handler.setVue(this)
      handler.execute(repository, [params])
    },
    getProjectCodes() {
      const handler = projectCodeIndexHandler
      const repository = projectCodeRepository.index
      const params = {
        search: this.searchProjectCode,
      }
      handler.setVue(this)
      handler.execute(repository, [params])
    },
    voucherPaidBy() {
      const handler = voucherPaidByHandler
      const repository = voucherPaidByRepository.index
      handler.setVue(this)
      handler.execute(repository)
    },
    getPersonIndex() {
      const handler = personIndexHandler
      const repository = personRepository.index
      const params = { search: this.personIndexSearch }
      handler.setVue(this)
      handler.execute(repository, [params])
    },
    getVoucherData() {
      const handler = voucherDataHandler
      const repository = voucherRepository.edit
      handler.setVue(this)
      handler.execute(repository, [this.voucherId])
    },
    async update() {
      const handler = voucherUpdateHandler
      const repository = voucherRepository.update
      const { id, ...data } = this.getUpdateData()
      const confirm = await this.confirmUpdate()
      if (!confirm) return
      
      handler.setVue(this)
      handler.execute(repository, [id, data])
    },
    getUpdateData() {
      const { payToType } = this
      const defaults = { ...inputVars(), payToType }
      const getPayTo = () => {
        const { payTo } = this
        if (payTo.info) return payTo.info.id
        return payTo.id
      }
      return this.$getInputData(defaults, (result, key) => {
        const snakeKey = key.camelToSnakeCase()
        if (key === 'payTo') {
          result[snakeKey] = getPayTo()
          return result
        }
        result[snakeKey] = this[key]
        return result
      })
    },
    confirmUpdate() {
      const config = {
        title: 'Update',
        icon: 'mdi-alert',
        color: 'yellow darken-3',
      }
      return this.$confirm('Are you sure you want to update this voucher ?', config)
    },
  },
}
</script>
