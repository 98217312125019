import repository from './repository'

const resource = 'vouchers'

export default {
  index (params) {
    return repository.get(resource, { params })
  },
  edit (id, params) {
    return repository.get(`${resource}/${id}`, { params })
  },
  store (data) {
    return repository.post(resource, data)
  },
  update (id, data) {
    return repository.put(`${resource}/${id}`, data)
  },
  approve (id) {
    return repository.post(`${resource}/${id}/approve`)
  },
  reject (id) {
    return repository.post(`${resource}/${id}/reject`)
  },
  payToList (params) {
    return repository.get(`${resource}/pay-to-list`, { params })
  },
  // getDetails (id) {
  //   return repository.get(`${resource}/${id}/details`)
  // },
  exportExcel (params) {
    const conf = {
      headers: {
        Accept: 'application/xlsx',
      },
      responseType: 'blob',
      params,
    }
    return repository.get(`${resource}/export-excel`, conf)
  },
  getAccountCodes (id) {
    return repository.get(`${resource}/${id}/account-codes`)
  },
  details (id) {
    return repository.get(`${resource}/${id}/details`)
  },
  printPdf (id) {
    const conf = {
      headers: {
        Accept: 'application/pdf',
      },
      responseType: 'text', 
    }
    return repository.get(`${resource}/${id}/print-pdf`, conf)
  },
  complete (id) {
    return repository.post(`${resource}/${id}/complete`)
  },
}
